import Vue from "vue"
import { ToastPlugin, ModalPlugin } from "bootstrap-vue"
import VueCompositionAPI from "@vue/composition-api"

import router from "./router"
import store from "./store"
import App from "./App.vue"

// Global Components
import "./global-components"

// 3rd party plugins
import "@/libs/portal-vue"
import "@/libs/toastification"

import msal from "vue-msal"

Vue.use(msal, {
    auth: {
        clientId: "77a4340f-68f7-4d3e-b437-2afbdf9c5d84",
        tenantId: "4e07a55b-3558-440d-8c13-87a67708bffe",
        //authority: "https://login.microsoftonline.com/4e07a55b-3558-440d-8c13-87a67708bffe/",
        knownAuthorities: [],
        redirectUri: 'http://localhost:8080',
        //redirectUri: 'https://www.lranka.de',
        requireAuthOnInitialize: true,
        postLogoutRedirectUri: '',
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message)
                return
              case LogLevel.Info:
                console.info(message)
                return
              case LogLevel.Verbose:
                console.debug(message)
                return
              case LogLevel.Warning:
                console.warn(message)
                return
            }
          },
        },
      },
});

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require("@core/scss/core.scss")

// import assets styles
require("@/assets/scss/style.scss")

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")